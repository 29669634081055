<script lang="ts" setup>
import type {
  RegistrationChangePhoneNumberStageEmits,
} from 'web/src/modules/registration/components/RegistrationChangePhoneNumber/types';
import { VForm } from 'web/src/components/Form';
import SupportLink
  from 'web/src/modules/phone-validation/components/PhoneValidationSupportLink/PhoneValidationSupportLink.vue';
import {
  useChangePhoneNumberStage,
} from 'web/src/modules/registration/components/RegistrationChangePhoneNumber/useChangePhoneNumberStage';

const emits = defineEmits<RegistrationChangePhoneNumberStageEmits>();

const {
  isFormPending,
  submittedPhoneStarred,
  uiSchema,
  schema,
  customErrors,
  emitInput,
  onChangePhoneSubmit,
} = useChangePhoneNumberStage(emits);
</script>

<template>
  <div v-auto-id="'RegistrationChangePhoneNumber'" :class="$style['registration-change-phone']">
    <div :class="$style['registration-change-phone__hint']">
      {{ null ? $t('WEB2_YOUR_CURRENT_PHONE_NUMBER') : $t('WEB2_YOUR_CURRENT_NUMBER') }}
      <span :class="$style['registration-change-phone__hint--current-phone']">{{ submittedPhoneStarred }}</span>
    </div>
    <VForm
      :class="$style['registration-change-phone__form']"
      :schema="schema"
      :ui-schema="uiSchema"
      :custom-errors="customErrors"
      :is-pending="isFormPending"
      @submit="onChangePhoneSubmit"
      @input="emitInput"
    />
    <SupportLink v-if="null" />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .registration-change-phone {
    @if $isEnvProductLi {
      padding: if($isDesktop, 0 32px 32px, 0 16px);
    }

    @if $isEnvProductSunny {
      padding: if($isDesktop, 0 32px 32px, 0 16px);
    }

    &__form {
      margin-top: 20px;
    }

    &__hint {
      @include regular\14\24\025;

      margin-top: 16px;
      color: var(--TextPrimary);
      text-align: center;

      &--current-phone {
        color: var(--TextDefault);

        @if $isDesktop {
          display: block;
        }
      }
    }
  }
}
</style>
