import { toRef } from 'vue';

import { AlertIconName, IconName } from '@leon-hub/icons';

import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';
import { useSiteConfigStore } from '@core/site-config';

import { ModalWidth } from '@components/dialogs';

import { getImageOrIcon } from 'web/src/modules/icons';
import { useRegistrationComplete } from 'web/src/modules/registration/store/composables/useRegistrationComplete';
import { useRegistrationProgress } from 'web/src/modules/registration/submodules/composables/useRegistrationProgress';

export interface UseRegistrationShowLeaveModalComposable {
  showLeaveModal(confirmCallback?: () => void, closeCallback?: () => void): Promise<void>;
}

export function useRegistrationShowLeaveModal(): UseRegistrationShowLeaveModalComposable {
  const siteConfigStore = useSiteConfigStore();
  const registrationProgress = useRegistrationProgress();
  const isRegistrationComplete = useRegistrationComplete();
  const { $translate } = useI18n();
  const { showDialog } = useDialogs();

  const showModalOnLeaveEnabled = toRef(siteConfigStore, 'showModalOnLeaveEnabled');
  const isNotFinished = toRef(registrationProgress, 'isNotFinished');

  function showLeaveModal(confirmCallback?: () => void, closeCallback?: () => void): Promise<void> {
    if (!showModalOnLeaveEnabled.value) {
      return Promise.resolve();
    }

    if (!isNotFinished.value) {
      return Promise.resolve();
    }

    if (isRegistrationComplete.value) {
      return Promise.resolve();
    }

    const { iconName, image } = getImageOrIcon({ icon: IconName.FAST_GAMES, alertIcon: AlertIconName.Present2 });
    const { promise, subscribe } = showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        confirmMessage: $translate('WEB2_REG_ON_LEAVE_MSG').value,
        title: $translate('WEB2_REG_ON_LEAVE_TITLE').value,
        iconName,
        image,
        isProfileLayout: !!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && !!process.env.VUE_APP_LAYOUT_DESKTOP,
        buttons: [
          {
            action: DialogAction.CONFIRM,
            label: $translate('WEB2_REG_ON_LEAVE_BACK_BUTTON').value,
          },
          {
            action: DialogAction.SECONDARY_CLICK,
            label: $translate('WEB2_REG_ON_LEAVE_CLOSE_BUTTON').value,
          },
        ],
        width: ModalWidth.MEDIUM,
      },
    });
    subscribe({
      [DialogAction.CONFIRM]: () => {
        confirmCallback?.();
      },
      [DialogAction.SECONDARY_CLICK]: () => {
        closeCallback?.();
      },
      [DialogAction.MODAL_CLOSE]: () => {
        confirmCallback?.();
      },
    });

    return promise;
  }

  return {
    showLeaveModal,
  };
}
