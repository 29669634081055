import type { Ref } from 'vue';
import { watch } from 'vue';

import { PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';

export const EXCLUDED_COUNTRY_DIALOG_ID = 'excluded-country-dialog';

export function useExcludedCountryDialog(isExcludedCountry: Ref<boolean>): void {
  const { showDialog } = useDialogs();
  const { $translate } = useI18n();

  watch(isExcludedCountry, (value) => {
    if (value) {
      showDialog({
        presetName: PresetName.CMS_MODAL,
        options: {
          title: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
            ? $translate('WEB2_EXCLUDED_COUNTRY_MODAL_TITLE').value
            : $translate('WEB2_IMPORTANT_TITLE').value,
          cmsKey: 'WEB2_REGISTRATION_CAUTION',
          fullHeight: false,
          iconName: undefined,
          buttons: [
            {
              label: $translate('WEB2_NEXT').value,
            },
          ],
        },
        id: EXCLUDED_COUNTRY_DIALOG_ID,
      });
    }
  }, { immediate: true });
}
